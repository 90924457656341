import React from 'react';
import { graphql, withPrefix, Link } from 'gatsby';
import Helmet from 'react-helmet';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import Call from '../components/Call';
import HeroArea from '../components/HeroArea';
import image1 from '../images/logos/twin-cities-mn-hvac-company.jpg';
import image2 from '../images/logos/minnesota-home-hvac-company.jpg';
import image3 from '../images/logos/minnesota-furnace-repair.jpg';
import image4 from '../images/logos/home-furnace-colling-hvac-minnesota.jpg';
import image5 from '../images/logos/furnace-ac-repair-minnesota.jpg';
import image6 from '../images/logos/minnesota-hvac-company.jpg';

import ImageBanner from '../images/FinancingOptions_LearnMore_728x90_B.png';
const Rebate = (props) => {
  return (
    <Layout bodyClass="page-financing">
      <SEO
        title="HVAC Rebates With LaSalle"
        description="Minneseota HVAC Rebate Programs"
      />
      <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
        <div className="text-center">
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            <span className="block xl:inline">LaSalle Heating and Cooling</span>{' '}
            <span className="block text-blue-600 xl:inline">
              System Rebates
            </span>
          </h1>
          <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            Questions about your rebate? Feel free to email us, call, or chat
            using the buttons below. Schedule Your HVAC Service, Intallation, or
            Repair by:
          </p>
          <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
            <div className="rounded-md shadow">
              <Link
                to="http://localhost:8000/hvac-service-installation-repair-scheduling"
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 md:py-4 md:text-lg md:px-10"
              >
                Email
              </Link>
            </div>
            <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
              <a
                href="tel:952-435-3633"
                className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-blue-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10"
              >
                Phone
              </a>
            </div>
          </div>
        </div>
      </main>
      <div className="container22 prose max-w-prose mx-auto py-20">
        <h2>Rebates:</h2>
        <p>
          Your utility providers for gas and electric will often have rebates
          for selecting higher efficiency equipment. Our team will find you all
          the possible rebates that you may be eligible for with the
          installation and annual maintenance of these systems.
        </p>
        <div className="row justify-content-start">
          <div className="col-6 col-md-4 rebate-logos">
            <a href="https://www.mvec.net/" target="_blank">
              <img src={image1} alt="twin-cities-mn-hvac-company" />
            </a>
          </div>
          <div className="col-6 col-md-4 rebate-logos">
            <a href="https://www.xcelenergy.com/" target="_blank">
              <img src={image2} alt="minnesota-home-hvac-company" />
            </a>
          </div>
          <div className="col-6 col-md-4 rebate-logos">
            <a href="https://www.dakotaelectric.com/" target="_blank">
              <img src={image3} alt="minnesota-furnace-repair" />
            </a>
          </div>
          <div className="col-6 col-md-4 rebate-logos">
            <a
              href="https://accel.minnesotaenergyresources.com/"
              target="_blank"
            >
              <img src={image4} alt="home-furnace-colling-hvac-minnesota" />
            </a>
          </div>
          <div className="col-6 col-md-4 rebate-logos">
            <a
              href="https://www.centerpointenergy.com/en-us/residential?sa=mn"
              target="_blank"
            >
              <img src={image5} alt="furnace-ac-repair-minnesota.jpg" />
            </a>
          </div>
          <div className="col-6 col-md-4 rebate-logos">
            <a href="https://spucweb.com/" target="_blank">
              <img src={image6} alt="minnesota-hvac-company" />
            </a>
          </div>
        </div>
        <ul>
          <li>
            <a href="https://www.mvec.net/" target="_blank">
              MVEC
            </a>
          </li>
          <li>
            <a href="https://www.xcelenergy.com/" target="_blank">
              XCEL ENERGY
            </a>
          </li>
          <li>
            <a href="https://www.dakotaelectric.com/" target="_blank">
              Dakota Electric
            </a>
          </li>
          <li>
            <a
              href="https://accel.minnesotaenergyresources.com/"
              target="_blank"
            >
              MN Energy Resources
            </a>
          </li>
          <li>
            <a
              href="https://www.centerpointenergy.com/en-us/residential?sa=mn"
              target="_blank"
            >
              Centerpoint Energy
            </a>
          </li>
          <li>
            <a href="https://spucweb.com/" target="_blank">
              Shakopee Public Utilities
            </a>
          </li>
        </ul>
        <p>
          * In addition to local rebates and other promotions, Federal Tax
          Credits have been retroactively extended to December 31, 2023 on
          qualifying residential energy efficient equipment. Please visit{' '}
          <a href="https://www.energystar.gov/about/federal_tax_credits/non_business_energy_property_tax_credits">
            EnergyStar.gov{' '}
          </a>{' '}
          for complete details on these incentives for residential/non business
          properties.
        </p>
        <p>
          * Tax deductions for commercial buildings are also being retroactively
          extended to December 31, 2023 Please visit{' '}
          <a href="https://www.energystar.gov/about/federal_tax_credits/federal_tax_credit_archive/tax_credits_commercial_buildings">
            EnergyStar.gov
          </a>{' '}
          for complete details on these incentives.
        </p>
      </div>
    </Layout>
  );
};

export default Rebate;
